import React, { useState, useEffect, useRef, useContext, createContext } from "react";
import "./cms-style.css";
import { guiColorPicker } from "../func/guiColorPicker";
import * as BABYLON from "@babylonjs/core";
import { cameraSettings } from "../babylonSetup/cameraSettings";
import { animateCameraPromise } from "../func/animateCameraPromise";
import axios from 'axios';
import {SceneContext} from '../BabylonComponent'


export function Cms({scene,  confSetup }) {

	cameraSettings({ scene, cameraSettings: confSetup.defaultCameraSettings });
	return (
		<div className='cms-overlay'>
			<div className='cms-style'>
				<div className='cms-style'>
					Console.log()--------------------------------------------------------------------------------------
				</div>
				<CMS_ConsoleButton items={"meshes"} scene={scene} />
				<CMS_ConsoleButton items={"materials"} scene={scene} />
				<CMS_ConsoleButton items={"lights"} scene={scene} />
				<div className='cms-style'>
					environemnt--------------------------------------------------------------------------------------
				</div>
				<CMS_Environment confSetup={confSetup} scene={scene} />
				<div>
					Light--------------------------------------------------------------------------------------
				</div>
				{confSetup.lights.map((light, index) => (
					<CMS_Light key={index} light={light} scene={scene} />
				))}
				<div className='cms-style'>
					foucusPoints--------------------------------------------------------------------------------------
				</div>
				{confSetup.focusPoints.map((item, index) => (
					<CMS_FocusPoint key={index} item={item} scene={scene} confSetup={confSetup} />
				))}
				<div className='cms-style'>
					materialGroups--------------------------------------------------------------------------------------
				</div>
				{confSetup.material55.map((item, index) => (
					<CMS_Material key={index} item={item} scene={scene} confSetup={confSetup} />
				))}
				<DisplaySceneObjects type='meshes' scene={scene} />
				<DisplaySceneObjects type='materials' scene={scene} />
				<DisplaySceneObjects type='lights' scene={scene} />

				<button onClick={() => writeSomething()}> hej </button>
			</div>
		</div>
	);
}

async function writeSomething() {
	
	// console.log(process.env.REACT_APP_SECRET_NAME)
	const { data } = await axios.get('http://localhost:1337/api/', {
	  headers: {
		Authorization:
		`Bearer ${process.env.REACT_APP_SECRET_NAME}`
	  },
	});
	

}

function DisplaySceneObjects({ type, scene }) {
	return (
		<div className='cms-style'>
			{type}-----------------
			{scene[type].map((item, index) => (
				<div key={index}>
					{index} - {item.name}
				</div>
			))}
		</div>
	);
}

function CMS_Material({ item, scene }) {

	let material = scene.getMaterialByName(item.material);

	const getJsonHandler = () => {
		let material = scene.getMaterialByName(item.material);
		let jsonData = {
			...item,
			albedoColorHex: material.albedoColor.toHexString(),
			settings: {
				roughness: Number(material.roughness),
				metallic: Number(material.metallic),
				alpha: Number(material.alpha),
			},
		};
		console.log("COPY THIS TO ./CONFIGURATOR/CONFIGURATOR1.JS");
		console.log(JSON.stringify(jsonData));
		navigator.clipboard.writeText(JSON.stringify(jsonData));
	};

	let sliderSettings = [];
	if (item.settings !== undefined) {
		for (const key in item.settings) {
			sliderSettings.push({ key, value: item.settings[key] });
		}
	}


	return (
		<div className='cms-style'>
			<button onClick={getJsonHandler}>JSON</button>
			<button
				onClick={() => {
					guiColorPicker(material);
				}}>
				COLOR
			</button>
			{item.name}
			{sliderSettings.map((item2) => {
				const materialSettingsHandler = (value) =>
					// const [state, setState] = useState(second)
					(material[item2.key] = value);
				return (
					<span key={item2.key}>
						{item2.key}-{item2.value}
						<CMS_Slider
							value={item2.value}
							actionHandler={materialSettingsHandler}
							max={1}
							step={0.01}
						/>
					</span>
				);
			})}
		</div>
	);
}

function CMS_Environment({ confSetup, scene }) {
	const backgrounRotateHandler = (value) =>
		(scene.environmentTexture.rotationY = BABYLON.Tools.ToRadians(value));
	const exposureHandler = (value) => (scene.imageProcessingConfiguration.exposure = value);
	const contrastHandler = (value) => (scene.imageProcessingConfiguration.contrast = value);
	const intensityHandler = (value) => (scene.environmentIntensity = value);
	const backgroundImageHandler = (item) => {
		const path = process.env.PUBLIC_URL + confSetup.sceneSettings.envPath;
		const file = process.env.PUBLIC_URL + path + item;
		scene.environmentTexture = BABYLON.CubeTexture.CreateFromPrefilteredData(file, scene);
	};

	const getJsonHandler = () => {
		const envPath = scene.environmentTexture.url
			.split("/")
			.map((item, index) => {
				if (item.includes(".")) return;
				return item;
			})
			.join("/");

		const envActive = scene.environmentTexture.url
			.split("/")
			.map((item, index) => {
				if (!item.includes(".")) return;
				return item;
			})
			.join("");

		let jsonData = {
			...confSetup.sceneSettings,
			babylonFile: confSetup.sceneSettings.babylonFile,
			envPath,
			envFiles: confSetup.sceneSettings.envFiles,
			envActive,
			envdRotationY: BABYLON.Tools.ToDegrees(scene.environmentTexture.rotationY),
			contrast: Number(scene.imageProcessingConfiguration.contrast),
			exposure: Number(scene.imageProcessingConfiguration.exposure),
			intesity: Number(scene.environmentIntensity),
		};
		console.log("COPY THIS TO ./CONFIGURATOR/CONFIGURATOR1.JS");
		console.log(JSON.stringify(jsonData));
		navigator.clipboard.writeText(JSON.stringify(jsonData));
	};

	return (
		<div className='cms-style'>
			<div>
				<button onClick={getJsonHandler}>JSON</button>
				<div>
					rotation:{" "}
					<CMS_Slider
						value={BABYLON.Tools.ToDegrees(scene.environmentTexture.rotationY)}
						actionHandler={backgrounRotateHandler}
						max={360}
					/>
				</div>
				<div>
					exposure:{" "}
					<CMS_Slider
						value={scene.imageProcessingConfiguration.exposure}
						actionHandler={exposureHandler}
						max={3}
						step={0.01}
					/>
				</div>
				<div>
					contrast:{" "}
					<CMS_Slider
						value={scene.imageProcessingConfiguration.contrast}
						actionHandler={contrastHandler}
						max={3}
						step={0.01}
					/>
				</div>
				<div>
					intesity:{" "}
					<CMS_Slider
						value={scene.environmentIntensity}
						actionHandler={intensityHandler}
						max={3}
						step={0.01}
					/>
				</div>
				Env file:
				{confSetup.sceneSettings.envFiles.map((item, index) => {
					return (
						<button
							key={index}
							onClick={() => {
								backgroundImageHandler(item);
							}}>
							{item.slice(0, 12)}
						</button>
					);
				})}
			</div>
		</div>
	);
}

function CMS_Light({ light, scene }) {
	const lightIntensityHandler = (value) => {
		let lightA = scene.getLightByName(light.name);
		lightA.intensity = value;
	};

	const getJsonHandler = () => {
		let lightA = scene.getLightByName(light.name);
		let lightMesh = scene.getMeshByName(light.name);

		let position = { ...lightMesh._absolutePosition };

		let jsonData = {
			name: lightA.name,
			type: light.type,
			intensity: Number(lightA.intensity),
			position: {
				x: Number(position._x.toFixed(2)),
				y: Number(position._y.toFixed(2)),
				z: Number(position._z.toFixed(2)),
			},
			diffuse: { r: lightA.diffuse.r, g: lightA.diffuse.g, b: lightA.diffuse.b },
			specular: { r: lightA.specular.r, g: lightA.specular.g, b: lightA.specular.b },
		};
		console.log("COPY THIS TO ./CONFIGURATOR/CONFIGURATOR1.JS");
		console.log(JSON.stringify(jsonData));
		navigator.clipboard.writeText(JSON.stringify(jsonData));
	};

	const togleLightHandler = () => {
		scene.getMeshByName(light.name).isVisible = !scene.getMeshByName(light.name).isVisible;
	};

	return (
		<div className='cms-style'>
			<button onClick={getJsonHandler}>JSON</button>
			{light.name}
			<button onClick={togleLightHandler}>show</button>
			
			<CMS_Slider
				value={scene.getLightByName(light.name).intensity}
				actionHandler={lightIntensityHandler}
				max={5}
				step={0.01}
			/>
		</div>
	);
}

function CMS_FocusPoint({ item, scene, confSetup }) {
	const meshSizeHandler = (value) => {
		let mesh = scene.getMeshByName(item.name);
		mesh.scaling = new BABYLON.Vector3(value, value, value);
	};

	const getJsonHandler = () => {
		let mesh = scene.getMeshByName(item.name);
		let position = { ...mesh._absolutePosition };

		let jsonData = {
			...item,
			mesh: mesh.name,
			position: {
				x: Number(position._x.toFixed(2)),
				y: Number(position._y.toFixed(2)),
				z: Number(position._z.toFixed(2)),
			},
			scaling: Number(mesh.scaling.x),
			alpha: Number(BABYLON.Tools.ToDegrees(scene.cameras[0].alpha).toFixed(0)),
			beta: Number(BABYLON.Tools.ToDegrees(scene.cameras[0].beta).toFixed(0)),
			radius: Number(scene.cameras[0].radius.toFixed(2)),
		};
		console.log("COPY THIS TO ./CONFIGURATOR/CONFIGURATOR1.JS");
		console.log(JSON.stringify(jsonData));
		navigator.clipboard.writeText(JSON.stringify(jsonData));
	};

	const isVisibleTogle = () => {
		scene.getMeshByName(item.name).isVisible = !scene.getMeshByName(item.name).isVisible;
	};

	return (
		<div className='cms-style'>
			<button onClick={getJsonHandler}>JSON</button>
			<button
				onClick={() => {
					animateCameraPromise({ scene, confSetup, focusPointName: item.name });
				}}>
				GO
			</button>
			{item.name}
			<button onClick={isVisibleTogle}>show</button>
			<CMS_Slider actionHandler={meshSizeHandler} max={2} step={0.01} />
		</div>
	);
}

function CMS_Slider({ name, actionHandler, max, step = 1, value = 0 }) {
	// const [value, setValue] = useState(0)
	return (
		<input
			type='range'
			min={0}
			step={step}
			max={max}
			defaultValue={value}
			className='ui-slider'
			id='ui-myRange'
			onChange={(e) => {
				actionHandler(e.target.value);
			}}
		/>
	);
}

function CMS_ConsoleButton({ items, scene }) {
	return (
		<button
			onClick={() => {
				console.log(items + "-----------------------------");
				scene[items].map((item, index) => console.log(index + " - " + item.name));
			}}>
			{items} console
		</button>
	);
}
