import * as BABYLON from "@babylonjs/core";

const SPEED_RATIO = 1;
const LOOP_MODE = false;
const FROM_FRAME = 0;
const TO_FRAME = 100;

export function animateCameraPromise({scene, confSetup, focusPointName}) {
	
	let focusPoint = confSetup.focusPoints.find((point) => point.name === focusPointName);
	if (focusPoint === undefined) console.log("MISSING !!! " + focusPointName)

	let mesh = scene.meshes.find((mesh) => mesh.name === focusPoint.target);
	

	return new Promise(function (resolve, reject) {
		
		// if no camera move is needed resolve.
		if (
			scene.cameras[0].alpha === BABYLON.Tools.ToRadians(focusPoint.alpha) &&
			scene.cameras[0].beta === BABYLON.Tools.ToRadians(focusPoint.beta) &&
			scene.cameras[0].radius === Number(focusPoint.radius) 
		) {
			resolve("done!")
		}



		startAnimateCamera(scene, {
			radius: Number(focusPoint.radius),
			alpha: BABYLON.Tools.ToRadians(focusPoint.alpha),
			beta: BABYLON.Tools.ToRadians(focusPoint.beta),
			target: mesh._absolutePosition,
		});


		function startAnimateCamera(
			scene,
			{ radius, alpha, beta, target: { x: targetX, y: targetY, z: targetZ } },
		) {
			const camera = scene.cameras[0];
		
			camera.animations = [
				createAnimation({
					property: "radius",
					from: camera.radius,
					to: radius,
				}),
				createAnimation({
					property: "beta",
					// from: simplifyRadians(camera.beta),
					from: camera.beta,
					to: beta,
				}),
				createAnimation({
					property: "alpha",
					// from: simplifyRadians(camera.alpha),
					from: camera.alpha,
					to: alpha,
				}),
				createAnimation({
					property: "target.x",
					from: camera.target.x,
					to: targetX,
				}),
				createAnimation({
					property: "target.y",
					from: camera.target.y,
					to: targetY,
				}),
				createAnimation({
					property: "target.z",
					from: camera.target.z,
					to: targetZ,
				}),
			];

			scene.beginAnimation(camera, FROM_FRAME, TO_FRAME, LOOP_MODE, SPEED_RATIO, () => {
				resolve("done!");
			});
		}
	});
}

const FRAMES_PER_SECOND = 60;

function createAnimation({ property, from, to }) {
	const ease = new BABYLON.CubicEase();
	ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);

	const animation = BABYLON.Animation.CreateAnimation(
		property,
		BABYLON.Animation.ANIMATIONTYPE_FLOAT,
		FRAMES_PER_SECOND,
		ease,
	);
	animation.setKeys([
		{
			frame: 0,
			value: from,
		},
		{
			frame: 100,
			value: to,
		},
	]);

	return animation;
}


