import { Engine, Scene } from "@babylonjs/core";
import React, { useEffect, useRef, useState } from "react";

// import { RenderCanvas } from "./style";
import "../../style.css";

const SceneComponent = (props) => {
	const reactCanvas = useRef(null);
	const [canvasActiv, setCanvasActiv] = useState("_disable")
	const {
		confSetup,
		antialias,
		engineOptions,
		adaptToDeviceRatio,
		sceneOptions,
		onRender,
		onSceneReady,
		setState,
		state,
		...rest
	} = props;

	useEffect(() => {
		if (reactCanvas.current) {
			const engine = new Engine(reactCanvas.current, antialias, engineOptions, adaptToDeviceRatio);
			const scene = new Scene(engine, sceneOptions);
			if (scene.isReady()) {
				setCanvasActiv(" active")
				props.onSceneReady(scene);
			} else {
				scene.onReadyObservable.addOnce((scene) => props.onSceneReady(scene));
			}

			engine.runRenderLoop(() => {
				if (typeof onRender === "function") {
					onRender(scene, confSetup, setState);
				}
				scene.render();
			});

			const resize = () => {
				scene.getEngine().resize();
			};

			if (window) {
				window.addEventListener("resize", resize);
			}

			return () => {
				scene.getEngine().dispose();

				if (window) {
					window.removeEventListener("resize", resize);
				}
			};
		}
	}, [reactCanvas]); // eslint-disable-line


	const ActiveClass = state.sceneIsLoading ? " disable" : " active"
	// const ActiveClass = " active"
	return (
		// <RenderCanvas className="renderCanvas" ref={reactCanvas} {...rest} />
		<canvas className={`renderCanvas${ActiveClass}`} ref={reactCanvas} {...rest} />
		// <canvas ref={reactCanvas} {...rest} />
	);
};

export default SceneComponent;
