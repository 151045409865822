import React, { useState, useEffect, useRef, useContext } from "react";
import { MaterialButtons } from "./MaterialControls";
import { MeshSlider } from "./MeshControls";
import { updateMesh } from "../func/updateGraphic";

import "../../style.css";

export function OptionsMenu({ scene, confSetup }) {
	const root = confSetup.menu55.find((item) => item.id === "root");
	return <OptionGroup scene={scene} parent={root} confSetup={confSetup} />;
}

function OptionGroup({ parent, scene, confSetup }) {
	const children = confSetup.menu55.filter((i) => parent.childrenId.includes(i.id));
	return (
		<div className={`${parent.flex ? "flex " : ""} ${parent.id}`}>
			{children.map((child, i) => {
				if (child.type === "group")
					return <OptionGroup key={i} scene={scene} parent={child} confSetup={confSetup} />;

				if (child.type === "text") return <TextLable key={i} item={child} confSetup={confSetup} />;

				if (child.type === "material")
					return <MaterialButtons key={i} item={child} confSetup={confSetup} scene={scene} />;

				if (child.type === "mesh") {
					if (child.isVisible)
						return <MeshSlider key={i} item={child} confSetup={confSetup} scene={scene} />;
				}
			})}
		</div>
	);
}

function TextLable({ item }) {
	return <label className={item.id}>{item.text}</label>;
}
