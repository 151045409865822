import BabylonComponent from "./babylon/BabylonComponent";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

async function loadNames(filePath, setConfSetup) {

	const response = await fetch(filePath);
	// const response = await fetch(filePath,  {cache: "no-cache"});
	const names = await response.json();
	const defaultSettings = await fetch(process.env.PUBLIC_URL + "babylon/conf_settings/default.json");

	const defaultSettingsJson = await defaultSettings.json();
	names[0].defaultCameraSettings = defaultSettingsJson[0].defaultCameraSettings
	setConfSetup(names[0]);
}

function App() {
	const [confSetup, setConfSetup] = useState(false);
	let query = useQuery();
	let configurator = query.get("c");

	if (configurator === null) configurator = "bag"

	
	useEffect(() => {
		loadNames(process.env.PUBLIC_URL + "babylon/conf_settings/" + configurator + ".json?v1", setConfSetup);
	}, []);

	return (
		<div className='App'>
			<header className='App-header'>
				{confSetup === false ? (
					<div></div>
				) : (
					<BabylonComponent configurator={configurator} conf={confSetup}></BabylonComponent>
				)}
			</header>
		</div>
	);
}

export default App;
