import React, { useState, useEffect, useRef } from "react";
import "../style.css";
import { Cms } from "./comp/Cms";

export default function SceneOptions({ scene, confSetup }) {
	
	const [settingsVisible, setSettingsVisible] = useState(false);

	useEffect(() => {
		scene.getEngine().resize();
	}, []);

	useEffect(() => {
		const listener = (event) => {
			if (event.code === "KeyW") {
				event.preventDefault();
				setSettingsVisible(!settingsVisible);
				scene.getEngine().resize();
			}
		};
		document.addEventListener("keydown", listener);

		return () => document.removeEventListener("keydown", listener);
	}, [settingsVisible]);

	return (
		<div>
			{settingsVisible && <Cms scene={scene} confSetup={confSetup} />}
		</div>
	);
}
