import * as BABYLON from "@babylonjs/core";
// import webHeaderImage from './staket_v1/staket.babylon';

const newPathIsOn = false;

const useLoadAsset = () => {
	const loadAsset = async ({ scene, confSetup, setState }) => {
		// const webHeaderImage = await import("./media/staket/staket.babylon");
		let path = process.env.PUBLIC_URL + confSetup.sceneSettings.babylonFile.path;
		const file = confSetup.sceneSettings.babylonFile.file;

		var assetsManager = new BABYLON.AssetsManager(scene);
		assetsManager.useDefaultLoadingScreen = false;
		assetsManager.onFinish = function(tasks) {};

		if (!newPathIsOn) var meshTask = assetsManager.addMeshTask("skull task", "", path, file);

		
		// NEW CODE

		meshTask.onSuccess = function(task) {
			// setState((prev) => ({ ...prev, assetIsReady: true }));
			task.loadedMeshes.map((item) => {
				item.isVisible = false;
			});

			if (newPathIsOn) {
				// NEW CODE
				const checkTexture = (material, texture) => {
					if (material[texture] !== null) {
						const file55 = material[texture].name;
						// material[texture] = new BABYLON.Texture(require(`${path2 + file55}`));

						if (file55.includes("shadow")) {
							material.albedoTexture.hasAlpha = true;
							material.useAlphaFromAlbedoTexture = true;
						}
					}
				};

				for (let index = 0; index < scene.materials.length; index++) {
					const material = scene.materials[index];

					checkTexture(material, "reflectionTexture");
					checkTexture(material, "albedoTexture");
					checkTexture(material, "bumpTexture");
					checkTexture(material, "metallicTexture");
					// checkTexture(material, "diffuseTexture");
				}
				// NEW CODE
			}

			// FETCH ..........................
			if (!newPathIsOn) {

				// async function fetchFile(filePath) {
				// 	return new Promise(async (resolve, reject) => {
				// 		const response = await fetch(filePath + "?" + new Date().getTime());
				// 		const imageBlob = await response.blob();
				// 		const reader = new FileReader();
				// 		reader.readAsDataURL(imageBlob);

				// 		reader.onloadend = () => {
				// 			const base64data = reader.result;
				// 			console.log(base64data);
				// 			resolve(base64data);
				// 		};
				// 	});
				// }
	
				// 	const response = await fetch(filePath);
				// 	// const response = await fetch(filePath,  {cache: "no-cache"});

				// NEW CODE
				const checkTexture = async (material, texture) => {

					if (!(material[texture] === null || material[texture] === undefined)) {
						const file55 = material[texture].name;
			
						if (confSetup.version === "" || confSetup.version === undefined) {
							material[texture] = new BABYLON.Texture(path + file55 + "?" + new Date().getTime());
						} else {
							material[texture] = new BABYLON.Texture(path + file55 + "?" + confSetup.version);
						}
						if (file55.includes("shadow")) {
							material.albedoTexture.hasAlpha = true;
							material.useAlphaFromAlbedoTexture = true;
						}
					}
				};

				for (let index = 0; index < scene.materials.length; index++) {
					const material = scene.materials[index];
					checkTexture(material, "reflectionTexture");
					checkTexture(material, "albedoTexture");
					checkTexture(material, "bumpTexture");
					checkTexture(material, "metallicTexture");
					// checkTexture(material, "diffuseTexture");
				}


				// NEW CODE
			}

			// setState((prev) => ({ ...prev, assetIsReady: true }));
		};

		meshTask.onError = function(task) {
			// console.log("ERROR - MISSING 3D FILE", task);
		};
		assetsManager.load();
	};

	return loadAsset;
};

export default useLoadAsset;

// console.log(uniqByKeepFirst(data, it => it.u))
// console.log(uniqByKeepLast(data, it => it.u))

// function uniqByKeepFirst(a, key) {
//   let seen = new Set();
//   return a.filter((item) => {
//     let k = key(item);
//     return seen.has(k) ? false : seen.add(k);
//   });
// }

// function uniqByKeepLast(a, key) {
// 	return [...new Map(a.map((x) => [key(x), x])).values()];
// }

// https://dev.to/vinodchauhan7/react-hooks-with-async-await-1n9g
// https://www.freecodecamp.org/news/how-to-create-react-hooks/
// https://ysfaran.github.io/blog/post/0002-use-state-with-promise/
