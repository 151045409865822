import React, { useState, useEffect, useRef, useContext } from "react";
import { updateMesh2 } from "../func/updateGraphic";
import { SceneContext } from "../BabylonComponent";

export function MeshSlider({ item, confSetup }) {
	// const confSetup = state.confSetup
	const ref = useRef();


	const menuOptions = item.childrenId.map((menuItem) => {
		return confSetup.meshes55.find((mesh) => mesh.id === menuItem);
	});

	// console.log(menuOptions);
	const options = confSetup.meshes55.filter((i) => item.childrenId.includes(i.id));
//   console.log(options)
	// const index = options.findIndex((i) => i.id === item.active);
	const index = menuOptions.findIndex((option) => option.id === item.active);
  
//   console.log(index)
	const context = useContext(SceneContext);
	const [value, setValue] = useState(index);

	const [show, setShow] = useState(false);

	useEffect(() => {
		sliderStyling();
		setShow(true);
		return () => {
			sliderStyling();
		};
	});

	const actionHandler = (item2) => updateMesh2(item2.id, context.scene, confSetup);

	return (
		<div className='length-content' style={show ? { opacity: 1 } : { opacity: 0 }}>
			<label>{item.text}</label>
			<div ref={ref} className='length-selection'>
				<input
					type='range'
					list='lengths'
					value={value}
					max={menuOptions.length-1}
					min={0}
					step={1}
					onChange={(e) => {
						// console.log(options[e.target.value]);
						actionHandler(menuOptions[e.target.value]);
						setValue(e.target.value);
					}}
				/>
				<div id='lengths'>
					{options.map((option, index) => (
						<span key={index} id={index} value={index}>
							{option.text}
						</span>
					))}
				</div>
				<output />
			</div>
		</div>
	);
}

function sliderStyling() {
	/* Range slider Length */
	document.querySelectorAll(".length-selection").forEach(function(ctrl) {
		var el = ctrl.querySelector("input");
		var dl = ctrl.querySelector("#lengths");
		var output = ctrl.querySelector("output");

		el.oninput = function() {
			// colorize step options
			ctrl.querySelectorAll("span").forEach(function(opt) {
				if (opt.id <= el.valueAsNumber) opt.style.backgroundColor = "#E4CC7A";
				else opt.style.backgroundColor = "#E3E3E3";
			});
			var valPercent =
				(el.valueAsNumber - parseInt(el.min)) / (parseInt(el.max) - parseInt(el.min));
			var style =
				"background-image: -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(" +
				valPercent +
				", #E4CC7A), color-stop(" +
				valPercent +
				", #E3E3E3));";
			dl.style = style;
			// Popup
			if ((" " + ctrl.className + " ").indexOf(" " + "length-selection" + " ") > -1) {
				var selectedOpt = ctrl.querySelector('span[value="' + el.value + '"]');
				//output.innerText = selectedOpt.text;
				output.innerText = selectedOpt.innerText;
				output.style.left = "50%";
				output.style.left =
					selectedOpt.offsetLeft + selectedOpt.offsetWidth / 2 - output.offsetWidth / 2 + "px";

				el.style.left = output.style.left;
			}
		};
		el.oninput();
	});
	window.onresize = function() {
		document.querySelectorAll(".length-selection").forEach(function(ctrl) {
			var el = ctrl.querySelector("input");
			el.oninput();
		});
	};
}
