import * as BABYLON from "@babylonjs/core";



export function updateMesh2(meshGroupId, scene, confSetup) {

	// console.log(meshGroupId)
	const meshGroup = confSetup.meshes55.find((meshGroup) => meshGroup.id === meshGroupId);

	scene.meshes.map((mesh) => {
		mesh.isVisible = false; // HIDE ALL ITEMS
	});

	if (meshGroup === undefined) console.log("MISSING !!! " + meshGroup);

	scene.meshes.map((mesh) => {
		meshGroup.target.map((t) => {
			if (mesh.name.includes(t) || mesh.name.includes("env")) {
				mesh.isVisible = true; // SHOW SELECTED ITEM
				if (mesh.name.includes("bag") && mesh.material.backFaceCulling)
					createBackFaceMesh({ mesh, scene });
			}
		});

	});
}

export function updateMaterial({ active, scene, confSetup }) {

	let materialGroup = confSetup.material55.find((item) => item.id === active);

	if (materialGroup === undefined) {
		console.log(active + " - MATERIAL IS MISSING!!! ");
		return;
	}

	const material = scene.getMaterialByName(materialGroup.material);
	if (material === null) {
		// var pbr = new BABYLON.PBRMetallicRoughnessMaterial("pbr", scene);
		// pbr.albedoColor = BABYLON.Color3.FromHexString(materialGroup.albedoColorHex);
		console.log("MISSING MATERIAL !!! ");
	} else {
		// checkTexture55(material, "albedoTexture", confSetup)

		material.albedoColor = BABYLON.Color3.FromHexString(materialGroup.albedoColorHex);
	}

	if (materialGroup.settings !== undefined) {
		for (const key in materialGroup.settings) {
			material[key] = materialGroup.settings[key];
		}
	}
}

function createBackFaceMesh({ mesh, scene }) {
	var backSideMesh = mesh.clone(mesh.name + "_Backside");
	var geom = backSideMesh.geometry.copy("testa");
	geom.applyToMesh(backSideMesh);
	backSideMesh.flipFaces(true);

	let backSideMaterial = new BABYLON.StandardMaterial("BacksideMaterial", scene);
	backSideMaterial.diffuseColor = BABYLON.Color3.FromHexString("696969");
	backSideMesh.material = backSideMaterial;
}

// NEW CODE
// function checkTexture55(material, texture, confSetup) {
// 	const path2 = confSetup.sceneSettings.babylonFile2.path;
// 	if (material[texture] !== null) {

// 		const file55 = material[texture].name;

// 		material[texture] = new BABYLON.Texture(require(`${path2 + file55}`));
// 		if (file55.includes("shadow")) {
// 			material.albedoTexture.hasAlpha = true;
// 			material.useAlphaFromAlbedoTexture = true;
// 		}
// 	}
// };

// for (let index = 0; index < scene.materials.length; index++) {
// 	const material = scene.materials[index];

// 	checkTexture(material, 'reflectionTexture')
// 	checkTexture(material, "albedoTexture");
// 	checkTexture(material, "bumpTexture");
// 	checkTexture(material, "metallicTexture");
// }
// NEW CODE
