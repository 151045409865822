import React, { useState, useEffect, useRef } from "react";
import { sceneActionHandler } from "../func/sceneActionHandler";
import { updateMaterial } from "../func/updateGraphic";

export function MaterialButtons({ scene, item, confSetup }) {
	// const confSetup = state.confSetup
	const [state2, setState2] = useState(item.active);

	useEffect(() => {
		updateMaterial({ active: state2, scene, confSetup });
	}, []);

	const options = confSetup.material55.filter((i) => item.childrenId.includes(i.id));

	const actionHandler = async (item) => {
		updateMaterial({ type: "material55", active: item.id, scene, confSetup });
		const action = confSetup.actions.find((i) => i.name === item.action);
		if (action === undefined) return;
		sceneActionHandler({ action, actionName: item.action, scene, confSetup });
	};

	return (
		<div className='colour-selection'>
			<span>{item.text}</span>
			{options.map((item, index) => (
				<button
					className={`${item.id === state2 ? "active" : ""} ${item.name}`}
					key={item.id}
					onClick={() => {
						actionHandler(item);
						setState2(item.id);
					}}></button>
			))}
		</div>
	);
}
