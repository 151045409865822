import React, { useState, useEffect, useRef, createContext } from "react";
import useLoadAsset from "./useLoadAsset";
import SceneComponent from "./babylonSetup/SceneComponent"; // uses above component in same directory
import sceneSetup from "./babylonSetup/sceneSetup";
import "../style.css";
import { onRender } from "./babylonSetup/sceneOnRender";
import { OptionsMenu } from "./comp/OptionsMenu";
import CmsOverlay from "./CmsOverlay";
// import { getConfigurator } from "./_settings/getConfigurator";
import Lottie from "react-lottie-player";

export const SceneContext = createContext();

function sceneIsReadyPromise(scene) {
	return new Promise((resolve, reject) => {
		const checkLoop = setInterval(() => {
			if (scene.isReady()) {
				// if (!scene.isLoading) {
				resolve();
				clearInterval(checkLoop);
			}
		}, 500);
	});
}

export default function BabylonComponent({ configurator, conf }) {
	if (configurator === null) configurator = "bag";
	// const [confSetup, setConfSetup] = useState(getConfigurator(configurator));
	const [confSetup, setConfSetup] = useState(conf);

	const [mainScene, setMainScene] = useState({});
	const [state, setState] = useState({
		sceneIsLoading: true,
		loadingIcon: "active",
		handIcon: "disable",
		assetIsReady: false,
		focusPoint: "active",
		confSetup: conf,
		// confSetup: getConfigurator(configurator),
		sceneObject: {},
		scene: {},
	});

	const loadAsset = useLoadAsset();

	let onSceneReady = async (scene) => {
		setMainScene(scene);
		sceneSetup(scene, confSetup);
		// setState((prev) => ({
		// 	...prev,
		// 	scene,
		// }));
		loadAsset({ scene, setState, confSetup });
		window["scene" + configurator] = scene;
		await sceneIsReadyPromise(scene);
		const meshes = scene.meshes.map((item, i) => `${item} - ${item.name}`);
		const materials = scene.materials.map((item, i) => `${i} - ${item.name}`);
		const lights = scene.lights.map((item, i) => `${i} - ${item.name}`);
		setState((prev) => ({
			...prev,
			loadingIcon: "disable",
			sceneIsLoading: false,
			sceneObject: { meshes, materials, lights },
			assetIsReady: true,
			scene,
		}));
		setTimeout(() => {
			setState((prev) => ({
				...prev,
				handIcon: "active",
			}));
		}, 1000);
	};

	const handIconHandler = () => setState((prev) => ({ ...prev, handIcon: "disable" }));

	return (
		<div className={`babylonComp ${configurator}`}>
			<div className={`loader-container ${state.loadingIcon}`}>
				<div className='loader'></div>
			</div>

			<div className={`help360-container flex ${state.handIcon}`}>
				<label>Rotera i 3D</label>
				<Lottie loop animationData={require(`./media/animation/helpHand.json`)} play />
			</div>

			<SceneComponent
				style={state.sceneIsLoading ? { opacity: 0 } : { opacity: 1 }}
				onPointerDown={() => {
					if (state.handIcon == "active") handIconHandler();
				}}
				antialias
				onSceneReady={onSceneReady}
				onRender={onRender}
				setState={setState}
				state={state}
				confSetup={confSetup}
				id='renderCanvas'
			/>
			<SceneContext.Provider value={{ scene: mainScene, value: "hejhej" }}>
				<div className={`overlay ${state.focusPoint}`}>
					{state.assetIsReady && <OptionsMenu scene={mainScene} confSetup={confSetup} />}
				</div>
				{state.assetIsReady && <CmsOverlay scene={mainScene} confSetup={confSetup} />}
			</SceneContext.Provider>
		</div>
	);
}
