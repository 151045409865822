import { addGuiNavButtons } from "../func/guiNavButtons";
import {  updateMesh2, updateMaterial } from "../func/updateGraphic";
import { addLight } from "../func/addLight";
import { addDraggableMesh } from "../func/addDraggableMesh";
import { cameraSettings } from "../babylonSetup/cameraSettings";

let firstRenderLoop = true;
let firstSceneIsReady = true;

export const onRender = async (scene, confSetup, setState) => {
	// FRIST RENDER LOOP
	if (firstRenderLoop) {
		firstRenderLoop = false;
		cameraSettings({ scene: scene, cameraSettings: confSetup.cameraSettings });
	}

	// FRIST TIME SCENE IS READY
	// if (!scene.isLoading && firstSceneIsReady) {
	if (scene.isReady() && firstSceneIsReady) {
		firstSceneIsReady = false;

		confSetup.lights.map((light) => {
			addLight({ light, scene, confSetup });
		});

		confSetup.focusPoints.map((item) => {
			addDraggableMesh({ item, scene, confSetup });
		});

		scene.meshes.map((mesh) => mesh.name.includes("env") && (mesh.isVisible = true));

		setTimeout(() => {
			updateMesh2(confSetup.meshesDefault, scene, confSetup);
		}, 100);

		confSetup.material55Default.map((active, index) => {
			updateMaterial({ active, scene, confSetup });
		});

		addGuiNavButtons(confSetup.focusPoints, confSetup, scene, setState);
	}
};
