// import * as GUI from "@babylonjs/core/gui";
import * as GUI from "@babylonjs/gui";
import conf_default from "../_settings/conf_default";
import { sceneActionHandler } from "./sceneActionHandler";

const buttonColor = "#e4cc7a";
// const buttonColor = "transparent";
const buttonHoverColor = "#ece0b5";
const fadeIn = (item) => {
	let time = 0;
	const timeValue = setInterval((interval) => {
		time = time + 0.05;
		item.alpha = time;
		if (time > 1) {
			item.alpha = 1;
			clearInterval(timeValue);
			item.isEnabled = true;
		}
	}, 10);
};

const fadeOut = (item) => {
	let time = 1;
	item.background = buttonColor;
	const timeValue = setInterval((interval) => {
		time = time - 0.05;
		item.alpha = time;
		if (time < 0) {
			item.alpha = 0;
			clearInterval(timeValue);
			item.isEnabled = false;
		}
	}, 10);
};

export function addGuiNavButtons(zoomDetailsArray, confSetup, scene, setState) {

	

	var advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");
	advancedTexture.color = "transparent";
	const focusPointsVisible = confSetup.focusPointsVisible.map((item) => {
		return confSetup.focusPoints.find((focusPoint) => focusPoint.name === item);
	});
	focusPointsVisible.map((focusPoint) => {
		// zoomDetailsArray.map((focusPoint) => {
		// if (focusPoint.target !== "focusPoint_default") {

		guiFocusButton(advancedTexture, focusPoint, scene, confSetup, setState);
		// }
	});
}

function guiFocusButton(advancedTexture, focusPoint, scene, confSetup, setState) {
	// let mesh = scene.meshes.find((item) => item.name === focusPoint.target);
	let mesh = scene.getMeshByName(focusPoint.name);

	const ellipse = new GUI.Ellipse();
	advancedTexture.addControl(ellipse);
	ellipse.name = focusPoint.name;
	ellipse.width = "60px";
	ellipse.height = "60px";
	ellipse.cornerRadius = 0;
	ellipse.color = "none";
	ellipse.thickness = 0;
	ellipse.background = buttonColor;
	ellipse.linkWithMesh(mesh);
	ellipse.linkOffsetY = 0;
	ellipse.linkOffsetX = 30;
	ellipse.linkOffsetZ = 30;
	ellipse.isPointerBlocker = true;

	if (focusPoint.lable.includes("img_")) {
		let img = focusPoint.lable.replace("img_","")
		var icon = GUI.Button.CreateImageOnlyButton("b1", conf_default.path + "/img/" + img);
		ellipse.addControl(icon);
		icon.image.stretch = GUI.Image.STRETCH_UNIFORM;
		icon.width = "30px";
		icon.height = "30px";
		icon.color = "transparent";
	} else {
		const label = new GUI.TextBlock();
		ellipse.addControl(label);
		label.text = focusPoint.lable;
		label.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
		label.linkOffsetX = 30;
		label.linkOffsetZ = 30;
		label.color = "white";
		label.scaleX = 1.5;
		label.scaleY = 1.5;
		ellipse.alpha = 0;
	}

	fadeIn(ellipse);

	ellipse.onPointerEnterObservable.add((event, eventState) => {
		ellipse.background = buttonHoverColor;
	});
	ellipse.onPointerOutObservable.add((event, eventState) => {
		ellipse.background = buttonColor;
	});

	ellipse.onPointerDownObservable.add(async (event, eventState) => {
		fadeOut(ellipse);
		setState((prev) => ({ ...prev, focusPoint: "disable" }));

		// Fade out all
		confSetup.focusPoints.map((item) => {
			let guiControl = advancedTexture.getControlByName(item.name);
			if (guiControl !== null) fadeOut(guiControl);
		});
		// const action = confSetup.actions.find((item) => item.name === focusPoint.action);
		await sceneActionHandler({
			// actionName: focusPoint.action,
			scene,
			confSetup,
			action: confSetup.actions.find((item) => item.name === focusPoint.action),
		});

		guiBackButton(advancedTexture, focusPoint, scene, confSetup, ellipse, setState);
	});

	ellipse.onPointerUpObservable.add((event, eventState) => {});
}

function guiBackButton(advancedTexture, focusPoint, scene, confSetup, returnButton, setState) {
	// BUTTON BACK WITH ICON
	const ellipseBack = new GUI.Ellipse();
	advancedTexture.addControl(ellipseBack);
	ellipseBack.width = "60px";
	ellipseBack.height = "60px";
	ellipseBack.cornerRadius = 0;
	ellipseBack.color = "none";
	ellipseBack.thickness = 0;
	ellipseBack.background = buttonColor;
	// ellipse.linkWithMesh(mesh);
	ellipseBack.linkOffsetY = 0;
	ellipseBack.linkOffsetX = -600;
	ellipseBack.linkOffsetZ = -100;
	ellipseBack.top = "-50px";
	ellipseBack.isPointerBlocker = true; // solved klick before
	// ellipseBack.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT;
	ellipseBack.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_BOTTOM;
	ellipseBack.alpha = 0;

	fadeIn(ellipseBack);

	// ICON TO BACK BUTTON
	const backIcon = GUI.Button.CreateImageOnlyButton("b2", conf_default.path + "/img/back.svg");
	backIcon.image.stretch = GUI.Image.STRETCH_UNIFORM;
	backIcon.width = "40px";
	backIcon.height = "40px";
	backIcon.color = "transparent";

	ellipseBack.addControl(backIcon);

	ellipseBack.onPointerEnterObservable.add((event, eventState) => {
		ellipseBack.background = buttonHoverColor;
	});
	ellipseBack.onPointerOutObservable.add((event, eventState) => {
		ellipseBack.background = buttonColor;
	});

	ellipseBack.onPointerDownObservable.add(async (event, eventState) => {
		setState((prev) => ({ ...prev, focusPoint: "active" }));
		fadeOut(ellipseBack);
		const action = confSetup.actions.find((item) => item.name === focusPoint.action + "-back");
		// const action = confSetup.actions.find((item) => item.name === focusPoint.action);
		await sceneActionHandler({
			action,
			// action : focusPoint.actionsBack,
			// actionName: focusPoint.action + "-back",
			scene,
			confSetup,
		});
		confSetup.focusPoints.map((item) => {
			let guiControl = advancedTexture.getControlByName(item.name);
			if (guiControl !== null) fadeIn(guiControl);
		});
	});
}
